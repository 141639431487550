<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/highlights'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage"
                        >Panel Name <strong>*</strong></label
                      >
                      <input
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div> 
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Image (450x315) <strong>*</strong></label
                      >
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('img', $event)"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Image Sub Text <strong>*</strong></label
                      >
                      <input
                        v-model="formData.img_sub_text"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Background Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.background_color"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Title Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.title_color"
                      value="#000"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>

                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Info Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.info_color"
                      value="#13a89e"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Description Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.description_color"
                      value="#000"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>

                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Link Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.link_color"
                      value="#13a89e"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" v-show="type == 2">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage"
                        >Panel Name <strong>*</strong></label
                      >
                      <input
                        v-model="formData.name2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div> 
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Image <strong>*</strong></label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('img2', $event)"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Image Sub Text <strong>*</strong></label
                      >
                      <input
                        v-model="formData.img_sub_text2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Background Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.background_color2"
                      value="#D9D9D9"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Title Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.title_color2"
                      value="#000"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>

                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Info Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.info_color2"
                      value="#13a89e"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Description Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.description_color2"
                      value="#000"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>

                  <div class="col-12 col-lg-2 d-flex">
                    <label for="seoKeyword">Link Color</label>
                    <b-form-input
                      id="color"
                      type="color"
                      name="color"
                      v-model="formData.link_color2"
                      value="#13a89e"
                      style="margin-left: 20px;"
                      class="form-control form-control-color"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Highlights Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Highlights Create",
      items: [
        {
          text: "Highlights",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        name: null,
        link: null,
        img: null,
        img_sub_text: null,
        background_color: "#D9D9D9",
        title_color: "#000",
        info_color: "#13a89e",
        description_color: "#000",
        link_color: "#13a89e",
        background_color2: "#D9D9D9",
        title_color2: "#000",
        info_color2: "#13a89e",
        description_color2: "#000",
        link_color2: "#13a89e",
      },
      value1: null,
      options: [],
      cats: [],
      type: this.$router.currentRoute.params.type,
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.Category.CategoryList).then((response) => {
        if (response.data.status == "success") {
          this.cats = response.data.order;
          this.formData.type = this.type;
        } else {
          this.cats = [];
        }
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Highlights.Create,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/highlights");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
